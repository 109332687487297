import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AutoFileDownloadService } from '../auto-file-download-service/auto-file-download.service';
import { SnackBarService } from '../snackBar-services/snack-bar.service';
@Injectable({
  providedIn: 'root',
})
export class OutboundService {
  rucUrl = environment.rucUrl;
  rootUrl = environment.rootUrl;
  baseUrl = environment.baseUrl;
  qlikViewGraphUrl = environment.qlikViewGraphUrl;
  trackntraceUrl = environment.baseUrl + '/trackntrace/order';
  snackBar = inject(SnackBarService);
  showOBDownloadSpinner = false;
  public urls = {
    getDocumentListByOrder: '/orderservice/orders/getDocumentListByOrder',
    getDocument: '/orderservice/orders/getdocument',
  };
  constructor(
    private readonly http: HttpClient,
    public autoFileDownloadService: AutoFileDownloadService
  ) {}
  getOutboundList(data) {
    return this.http.post<any>(this.baseUrl + '/listingview', data, {
      headers: { 'module-name': 'Outbound Listing' },
    });
  }
  getTrackedStatus() {
    /**
     * Date 23-oct-2024
     * as of now for prod revamp we will use trackstatus=mappedStatus
     * but for existing prod we need to use trackstatus=true as
     * changes are not yet deployed
     */
    return this.http.get<any>(
      this.baseUrl + '/orderstatus?trackstatus=mappedStatus'
    );
  }
  getFilterStatus() {
    return this.http.get<any>(this.baseUrl + '/orderstatus');
  }
  downloadOBData(payload: any) {
    const endUrl = '/oblist/download';
    this.showOBDownloadSpinner = true;
    this.http.post<any>(this.rootUrl + endUrl, payload).subscribe({
      next: (data) => {
        console.log(data);
        if (data?.status === 'Success') {
          this.showSuccessMsg(data);
        } else {
          console.log('no data recevied from report api ', data);
        }
        this.showOBDownloadSpinner = false;
      },
      error: (e) => {
        console.log('error while calling oblist download api ', e);
        this.showOBDownloadSpinner = false;
      },
    });
  }
  showSuccessMsg(data: any) {
    const clientName =
      'OB_LIST_DOWNLOAD_DIR/' + sessionStorage.getItem('companyname');
    const uuid = sessionStorage.getItem?.('uuid');
    Object.assign(data, { clientName, uuid });
    data.downloadOf = 'outbound';
    this.autoFileDownloadService.autoFileDownload(data);
  }
  getOutboundOrderDetails(data) {
    return this.http.post<any>(this.rucUrl + '/orderdetails', data);
  }
  getOutboundStatusOrHistory(payLoad) {
    return this.http.post(
      this.baseUrl + '/outbound/orderstatushistory',
      payLoad
    );
  }
  getOutboundReturnsLineData(payLoad) {
    return this.http.post(this.baseUrl + '/outbound/returns', payLoad);
  }
  getTracknTraceMilestones(payLoad) {
    return this.http.post(this.trackntraceUrl + '/milestones', payLoad);
  }
  getTracknTraceMilestoneEvents(payLoad) {
    return this.http.post(this.trackntraceUrl + '/events', payLoad);
  }
  cancelOrderLine(payLoad) {
    return this.http.put(this.rucUrl + '/orderline/cancel', payLoad);
  }
  editOrderLine(payLoad) {
    return this.http.put(this.rucUrl + '/orderline/edit', payLoad);
  }
  createOrderLine(payLoad) {
    return this.http.post(this.rucUrl + '/orderline/create', payLoad);
  }
  getDocumentsListByOrder(clientIdentifier, orderNo, dc) {
    const param = {
      clientId: clientIdentifier,
      orderId: orderNo,
      distributionCentre: dc,
    };
    return this.http.post<any>(
      this.rucUrl + this.urls.getDocumentListByOrder,
      param
    );
  }
  getDocument(documentRef) {
    const param = {
      documentReference: documentRef,
    };
    return this.http.post<any>(this.rucUrl + this.urls.getDocument, param);
  }
  updateAddress(address: any) {
    return this.http.post<any>(this.rucUrl + '/updateorder', address);
  }
  cancelorder(orderdetails: any) {
    return this.http.post<any>(this.rucUrl + '/cancelorder', orderdetails);
  }
}
